var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-main-container',{scopedSlots:_vm._u([{key:"centered",fn:function(){return [_c('v-card',{staticClass:"my-card rounded-lg",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("TaleForge: Create Short Stories ")]),_c('v-card-text',[_c('v-text-field',{staticClass:"ml-2",attrs:{"label":_vm.what == 'Intervention ideas' ? 'Specific behavior or academic area to address':'Topic/Subject',"outlined":""},model:{value:(_vm.topic),callback:function ($$v) {_vm.topic=$$v},expression:"topic"}}),_c('v-select',{staticClass:"mt-2 ml-2",attrs:{"append-icon":"mdi-filter","items":[
             'K',
             '1',
             '2',
             '3',
             '4',
             '5',
             '6',
             '7',
             '8',
             '9',
             '10',
             '11',
             '12',
           ],"label":"Suitable for Grade level","outlined":"","clearable":""},model:{value:(_vm.grade),callback:function ($$v) {_vm.grade=$$v},expression:"grade"}}),_c('v-combobox',{staticClass:"mt-2 ml-2",attrs:{"append-icon":"mdi-filter","items":['Funny', 'Friendly', 'Cold', 'Neutral'],"label":"Tone","outlined":"","clearable":""},nativeOn:{"input":function($event){_vm.tone=$event.srcElement.value}},model:{value:(_vm.tone),callback:function ($$v) {_vm.tone=$$v},expression:"tone"}}),_c('v-text-field',{attrs:{"label":"(Optional) Genre"},model:{value:(_vm.genre),callback:function ($$v) {_vm.genre=$$v},expression:"genre"}}),_c('v-text-field',{attrs:{"label":"(Optional) Character names, i.e. John, Eve and Mr.Brown "},model:{value:(_vm.characters),callback:function ($$v) {_vm.characters=$$v},expression:"characters"}}),_c('v-text-field',{attrs:{"label":"(Optional) In the voice of , i.e. Mark Twain, Homer Simpson etc.)"},model:{value:(_vm.voice),callback:function ($$v) {_vm.voice=$$v},expression:"voice"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","link":"","to":"newresource"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-backspace")]),_vm._v(" Cancel ")],1),_c('v-btn',{attrs:{"disabled":!((_vm.topic)),"color":"#212121"},on:{"click":function($event){return _vm.sendRequest()}}},[(
              !((_vm.topic))
             )?_c('h3',[_vm._v(" Generate please ")]):_c('h3',{staticStyle:{"color":"white"}},[_vm._v("Generate please")])]),_c('v-spacer')],1)],1)]},proxy:true}])},[_c('v-overlay',{attrs:{"value":_vm.isLoading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"68"}},[_vm._v(" AI is preparing your resource ")])],1),_c('v-snackbar',{attrs:{"timeout":6000,"color":"warning","top":""},model:{value:(_vm.showSnackbar),callback:function ($$v) {_vm.showSnackbar=$$v},expression:"showSnackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarMessege)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }